import Utility from "../../../shared/utility.js";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import breadcrumbComp from "../../common/breadcrumb-comp";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      file: "",
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      dragging: false,
      isHidden:true,
      isActive:true,
      uploadedFiles: [],
      selectedFile: [],
      uploadedFileDetails: [],
      uploadedFilePopupItems: [],
      totalRecords:0,
      tempUploadedItemArray:[],
      uploadedFileList: [],
      searchUploadedFileDetails: [],
      modalUpload: false,
      process: "OrderUploadCSV",
      userId: EncryptUtility.localStorageDecrypt("userID"),
      noFiles: true,
      headersUpload: [
        {
          text: "File Name",
          value: "OrigFileName",
          align: "start",
          class: "primary customwhite--text",
          width: "30%",
        },
        {
          text: "Uploaded Date",
          value: "UploadDate",
          class: "primary customwhite--text",
          width: "17%",
        },
        {
          text: "Processed Date",
          value: "ProcessedDate",
          class: "primary customwhite--text",
          width: "17%",
        },
        { text: "Status", value: "Status", class: "primary customwhite--text", width: "15%" },
        {
          text: "Uploaded By",
          value: "UploadedBy",
          class: "primary customwhite--text",
          width: "11%",
        },
        {
          text: "Error",
          value: "ViewDetails",
          class: "primary customwhite--text",
          width: "10%",
        },
      ],
      uploadPopupHeaders: [
        { text: "Order Id", value: "OrderID", align: "start", width: "16%" },
        { text: "Status", value: "Status", width: "10%" },
        { text: "Created Date", value: "CreatedDate", width: "22%" },
        { text: "Error Text", value: "ErrorText", width: "22%" },
        { text: "Batch", value: "BatchID", width: "30%" },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        size: (value) => {
          if (value.size > 50e6) return "Avatar size should be less than 50 MB!";
          else return true;
        },
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.getUploadedFileList();
  },
  methods: {
    //To add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //List of uploaded files
    getUploadedFileList() {
      this.uploadedFiles = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`co/uploaded_data?process=${this.process}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = response.data.body;
            const newArr = responseData.map((obj) => {
              return {
                ...obj,
                UploadDate: Utility.convertESTToLocal(obj.UploadDate),
                ProcessedDate: obj.ProcessedDate == null ? "" : Utility.convertESTToLocal(obj.ProcessedDate),
              };
            });
            this.uploadedFiles = newArr;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Uploaded File details
    getUploadedFileDetails(item) {
      this.uploadedFilePopupItems = [];
      let Batch = item.BatchId;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`co/processed_file_data/?batch_id=${Batch}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = response.data.body;
            const newArr = responseData.map((obj) => {
              return {
                ...obj,
                CreatedDate: Utility.convertESTToLocal(obj.CreatedDate),
              };
            });
            this.tempUploadedItemArray=newArr
            this.uploadedFilePopupItems = newArr;
            this.totalRecords= this.uploadedFilePopupItems?.length
            this.filterForHidden()
            this.modalUpload = true;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    //Downloading file API
    downloadFile(item) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("co/file_url?sys_file=" + item.SystemFileName)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            window.open(response.data.body, "_blank").focus();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //File Upload
    uploadFile() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      const formData = new FormData();
      formData.append("file", this.file);
      this.noFiles = true;
      this.axios
        .post("co/file_upload?process=" + this.process + "&user_id=" + this.userId, formData)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.getUploadedFileList();
            this.file = "";
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Drag and Drop Start
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.dragging = false;
        return;
      } else {
        this.noFiles = false;
        this.createFile(files[0]);
      }
    },
    //Checking file details
    createFile(file) {
      if (!file.type.match("csv.*")) {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select csv file.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.noFiles = true;
        this.dragging = false;
        return;
      }
      if (file.size > 50000000) {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Size is more than 50MB",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.noFiles = true;        
        this.dragging = false;
        return;
      }
      this.file = file;
      this.dragging = false;
    },
    //Removing dragged file
    removeFile() {
      this.file = "";
      this.noFiles = true;
    },

    onChangeHidden(){
      this.isHidden=!this.isHidden
      if(this.isHidden){
        this.filterForHidden()
      }
      else{
        this.uploadedFilePopupItems=this.tempUploadedItemArray
        this.totalRecords= this.uploadedFilePopupItems?.length
      }
    },
    //Closing details popup
    closeViewDetails() {
      this.modalUpload = false;
      this.dragging = false;
      this.isActive=true;
      this.isHidden=true;
    },
    //Drag and Drop End
    //Reset Function
    resetFunction() {
      this.modalUpload = false;
      this.file = "";
      this.noFiles = true;
      this.uploadedFiles = [];
      this.uploadedFilePopupItems = [];
      this.getUploadedFileList();
    },
    //Close the dialog popup
    closeModalUpload() {
      this.modalUpload = false;
      this.dragging = false;
      this.isActive=true;
      this.isHidden=true;
    },
    filterForHidden(){
      this.uploadedFilePopupItems=this.tempUploadedItemArray.filter((x)=>!x.ErrorText.includes("already exists"))
      this.totalRecords= this.uploadedFilePopupItems?.length
    }
  },
  components: {
    breadcrumbComp,
  },
};
